import React from 'react';
import { Link } from '@reach/router';
import { mediaUrl, siteRoot, gettext, mainPageLogoURL, showTutorialsVideo } from '../utils/constants';
import { Utils } from '../utils/utils';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          color: isCurrent ? '#ED7109' : '#666666',
          borderBottom: isCurrent ? '2px solid #ED7109' : '2px solid transparent'
        }
      };
    }}
  />
);

class HeaderPanel extends React.Component {

  render() {

    const templates = gettext('Templates');
    // const plugins = gettext('Plugins');
    // const cases = gettext('Cases');
    const videos = gettext('Videos');
    const isDesktop = Utils.isDesktop();
    return (
      <div id="header" className="plugin-market-header">
        {isDesktop && <a href={mainPageLogoURL} className="position-absolute"><img src={`${mediaUrl}img/logo.png`} alt="logo" width="128" /></a>}
        <div className="plugin-toolbar">
          {/* is not suppot gettext in the navlink */}
          <NavLink to={siteRoot} className="plugin-toolbar-item">{templates}</NavLink>
          {/* <NavLink to={siteRoot + 'plugins/'} className="plugin-toolbar-item">{plugins}</NavLink> */}
          {/* <NavLink to={siteRoot + 'cases/'} className="plugin-toolbar-item">{cases}</NavLink> */}
          {showTutorialsVideo &&
            <NavLink to={siteRoot + 'videos/'} className="plugin-toolbar-item">{videos}</NavLink>
          }
        </div>
      </div>
    );
  }
}

export default HeaderPanel;
